import { Stat, Tag } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { lookupEmission, PublicEmission } from '../../modules/public_api_module';
import MarketingHead from '../marketing-meta';
import Navbar from '../NavBar';
import * as qs from 'query-string';
import DisplayMap from '../HereMap/DisplayMap';

const LogisticsTypes = {
    "GROUND": "Ground Shipping",
    "AIR": "Air Freight"
}

const PublicEmissions = ({location}) => {
    const emission = PublicEmission.use();
    const {id} = qs.parse(location.search)

    const [points, setPoints] = useState(null)
    const [offsets, setOffsets] = useState([]);
    const [coverage, setCoverage] = useState(0);
    const [totalOffset, setTotalOffset] = useState(0);

    useEffect(() => {
        if (id) {
            lookupEmission(id)
        }
    }, [])

    useEffect(() => {
        let pts = emission?.data?.EmissionSegments.LogisticsSegments.map(em => ([
            { lat: em.Origin.Lat, lng: em.Origin.Lon },
            { lat: em.Destination.Lat, lng: em.Destination.Lon }
        ]))

        setPoints(pts)

        let ofs = emission?.data?.EmissionToQuotes.filter(q => q.Quote.Status === "PURCHASED")
        setOffsets(ofs);
        if (ofs) {
            setTotalOffset(ofs.reduce((p, c) => p + c.Quote.carbon_impact, 0));
            setCoverage(ofs.reduce((p, c) => p + c.CoveragePercent, 0))
        }
    }, [emission.data])

    return (
        <>
            <MarketingHead />
            <div className="bg-hero-image p-1 bg-cover min-h-1/3">
                <Navbar />
                <div className="container xl:px-40 mt-10 mb-4 mx-auto">
                    <div className="min-h-96 p-2 w-full text-white">
                        <p className="text-5xl font-bold">Track Emission</p>
                        <p className="mt-2 text-lg">Emission ID: {emission.data && id}</p>
                        <Tag mt={2} colorScheme="green">Parcel</Tag>
                    </div>
                </div>
                <div className="container xl:px-40 mb-8 mx-auto">
                    <div className="bg-white border py-5 border-gray-400 mx-2 shadow-lg">
                        <div className="grid grid-cols-1 lg:grid-cols-3 p-4">
                            <div>
                                <p className="text-md text-gray-500">Emission Impact</p>
                                <p className="text-4xl font-bold">{emission.data && emission.data.CarbonImpact} <span className="text-sm">KG CO2</span></p>
                            </div>
                            <div>
                                <p className="text-md text-gray-500">Percent Offset</p>
                                <p className="text-4xl font-bold">{emission.data && coverage}% <span className="text-sm">Covered</span></p>
                            </div>
                            <div>
                                <p className="text-md text-gray-500">Distance</p>
                                <p className="text-4xl font-bold">{emission.data && emission.data.EmissionSegments.LogisticsSegments.reduce((p,c) => (p + c.Distance), 0).toLocaleString('us-EN', { maximumFractionDigits: 0 })} <span className="text-sm">Miles</span></p>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="container xl:px-40 mb-8 mx-auto">
                <div className="p-4">
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 p-4 gap-4">
                    <div className="col-span-1">
                        <div>
                            <h2 className="text-2xl font-bold mb-2">Transit Emissions</h2>
                        </div>
                        <div className="space-y-3">
                        {emission.data && emission.data.EmissionSegments?.LogisticsSegments?.map(logSeg => (
                            <div className="rounded shadow-md bg-gray-50 border border-gray-200 p-2">
                                <h3 className="font-bold text-lg">{LogisticsTypes[logSeg.LogisticsType]}</h3>
                                <div className="grid grid-cols-2">
                                    <div>
                                        <p className="text-md text-gray-500">Emission Impact</p>
                                        <p className="text-4xl font-bold">{logSeg.CarbonImpact} <span className="text-sm">KG CO2</span></p>
                                    </div>
                                    <div>
                                        <p className="text-md text-gray-500">Distance</p>
                                        <p className="text-4xl font-bold">{logSeg.Distance.toFixed(2)} <span className="text-sm">Miles</span></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div>
                            <h2 className="text-2xl font-bold mb-2">Offsets</h2>
                        </div>
                        <div className="text-white bg-green-500 rounded shadow-md border border-primary p-2">
                            <h3 className="font-bold text-lg">Carbon Offset</h3>
                            <div>
                                <p className="text-md">Offset Impact</p>
                                <p className="text-4xl font-bold">{totalOffset} <span className="text-sm">KG CO2</span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="rounded-md">
                            {points && (
                                <DisplayMap points={points} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PublicEmissions;
