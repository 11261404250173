import React, { useLayoutEffect, useRef } from 'react';
import * as turf from '@turf/turf';

const DisplayMap = ({points}) => {
    const mapRef = useRef(null);

    useLayoutEffect(() => {
        const H = window.H;
        if (!mapRef.current || !H) return;
        const platform = new H.service.Platform({
            apiKey: "mhqqCVAR2SlOU0ujRqeJDZnyO07YvEq57Z6Asvaqi2E"
        });
        const defaultLayers = platform.createDefaultLayers();
        const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
            center: {lat: 50, lng: 5},
            zoom: 4,
            pixelRatio: window.devicePixelRatio || 1,
        });
        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));
        const ui = H.ui.UI.createDefault(hMap, defaultLayers);
        console.log(points)
        var ls = new H.geo.LineString();

        points.forEach(pair => {
            let start = turf.point([pair[0].lng, pair[0].lat])
            let end = turf.point([pair[1].lng, pair[1].lat])
            let gc = turf.greatCircle(start, end);
            gc.geometry.coordinates.forEach(p => {
                ls.pushPoint({lat:p[1], lng:p[0]})
            })
        })
        var polyline = new H.map.Polyline(ls, { style: { lineWidth: 6 }});
        hMap.addObject(polyline);

        var linestring = new H.geo.LineString();
        points.flat().forEach((point) => {
            linestring.pushPoint(point);
        })
        var polyline = new H.map.Polyline(linestring, { style: { lineWidth: 10 }});
        const bb = polyline.getBoundingBox()
        hMap.getViewModel().setLookAtData({bounds: bb, zoom: 4});

        return () => {
            hMap.dispose();
        };
    }, [mapRef]);

    return <div className="map rounded-md" ref={mapRef} style={{ height: "500px" }} />;
}

export default DisplayMap;
