import { entity } from 'simpler-state';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    timeout: 2000,
});

export const PublicEmission = entity({
    data: null,
    loading: false,
    errorMsg: '',
})

export const lookupEmission = async (uid) => {
    const result = await api.get(`/public/lookup/${uid}`)
    if (result.status !== 200) {
        PublicEmission.set(apiError, result.status)
    } else {
        PublicEmission.set(setEmission, result.data)
    }
}

const setEmission = (value, emission) => {
    return { ...value, data: emission }
}
const apiError = (value, errorMsg) => {
    return { ...value, loading: false, errorMsg }
}